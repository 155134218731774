import '@assets/main.css'
import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import localFont from 'next/font/local'
// import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Text } from '@components/ui'
import { Head, Layout } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { addCookie } from '@lib/utils/cookies'
import {
  useAllCollections,
  useFilterCollection,
} from 'lib/shopify/api/collection'

import 'intersection-observer'

import '../components/common/CookieConsent/styles.css'

const ThirdParty = dynamic(() => import('@components/common/ThirdParty'))

const DINNextLT = localFont({
  variable: '--font-DINNextLTPro',
  src: [
    {
      path: '../fonts/DINNextLTPro-Light.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Regular.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Medium.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Heavy.otf',
      weight: '800',
      style: 'normal',
    },
  ],
})

const FallbackComponent = () => {
  return (
    <div className="fit mx-auto flex max-w-2xl flex-col items-center justify-center px-10 py-20">
      <Text variant="heading">Oops, there is an error!</Text>
    </div>
  )
}

export default function MyApp({ Component, pageProps }) {
  const { locale } = useRouter()

  const { data } = useAllCollections()

  // const { metafields = {}, slug } = pageProps

  // const {
  //   menuPps = {},
  //   menuCharger = {},
  //   pageType = {},
  //   copywriting = {},
  //   navSetting = {},
  //   shop_collections = {},
  // } = metafields

  // const navData =
  //   pageType?.pageType === 'powerStation' || pageType?.pageType === 'hes'
  //     ? menuPps
  //     : menuCharger

  // let dropDownContent = navData?.dropDownContent || []

  // const collectionList = useMemo(() => {
  //   const list = []
  //   if (
  //     (copywriting?.navCategoriesIncludePages == true ||
  //       copywriting?.navCategoriesIncludePages?.includes(slug)) &&
  //     !navSetting?.noneHeader
  //   ) {
  //     dropDownContent?.forEach((item) => {
  //       if (['collections', 'newCollections']?.includes(item?.mode)) {
  //         if (item?.mode === 'collections') {
  //           Array.isArray(item?.content) &&
  //             item?.content?.forEach((collection) => {
  //               if (collection?.handle) {
  //                 list.push(collection?.handle)
  //                 if (Array.isArray(shop_collections?.[collection?.handle])) {
  //                   list?.push(...shop_collections?.[collection?.handle])
  //                 }
  //               }
  //             })
  //         } else {
  //           Array.isArray(item?.content) &&
  //             item?.content?.forEach((collection) => {
  //               if (collection?.handle) {
  //                 list.push(collection?.handle)
  //                 if (Array.isArray(shop_collections?.[collection?.handle])) {
  //                   list?.push(...shop_collections?.[collection?.handle])
  //                 }
  //               }
  //               if (Array.isArray(collection?.collectionList)) {
  //                 collection?.collectionList?.forEach((subCollection) => {
  //                   if (subCollection?.handle) {
  //                     list.push(subCollection?.handle)
  //                     if (
  //                       Array.isArray(shop_collections?.[subCollection?.handle])
  //                     ) {
  //                       list?.push(...shop_collections?.[subCollection?.handle])
  //                     }
  //                   }
  //                 })
  //               }
  //             })
  //         }
  //       }
  //     })
  //   }

  //   return list
  // }, [dropDownContent])

  // const { data: results } = useFilterCollection(collectionList, {})

  const PROPS = useMemo(
    () => ({
      ...pageProps,
      locale,
      categories: data || [],
      // navCategories: results || [],
    }),
    [data, locale, pageProps]
  )

  useEffect(() => {
    const { shop } = pageProps
    addCookie({ locale, shopifyUrl: shop?.primaryDomain?.url })
    document.body.classList?.remove('loading')
  }, [locale, pageProps])

  return (
    <>
      <Head pageProps={PROPS} />
      <ManagedUIContext>
        <Sentry.ErrorBoundary
          fallback={FallbackComponent}
          beforeCapture={(scope) => {
            scope.setTag('ClientError', 'components-error')
          }}
        >
          <Layout pageProps={PROPS}>
            <style jsx global>{`
              html {
                font-family: ${DINNextLT.style.fontFamily}, -apple-system,
                  system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica',
                  sans-serif;
              }
            `}</style>
            <Component {...PROPS} />
            <ThirdParty pageProps={PROPS} />
          </Layout>
        </Sentry.ErrorBoundary>
      </ManagedUIContext>
    </>
  )
}
