import { useState, useCallback, useMemo, useEffect } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { tagsToCollection, getCollection } from '@lib/utils/thirdparty'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './Crumbs.module.css'

const Crumbs = ({
  className,
  type,
  page,
  pageType,
  blogNav,
  blog,
  blog_article,
  product,
  collection,
  categories,
  landingpage,
  s: customClass = {},
}) => {
  const { setRelativeLink } = useRelativeLink()
  const router = useRouter()
  const { locale } = router

  const getCategory = useCallback(
    (handle, deHandle) =>
      categories?.find((cat) => cat.slug === handle || cat.slug === deHandle),
    [categories]
  )

  const followCollection = useMemo(() => {
    if (type === 'product' && product) {
      const target =
        product?.collections?.nodes?.find(
          (item) => !!landingpage?.[item?.handle]
        ) ||
        tagsToCollection(product?.tags) ||
        getCollection(product?.collections)

      let current = getCategory(target?.handle, target?.deHandle) || {}

      if (landingpage && landingpage?.[current?.slug]) {
        current.link = setRelativeLink({ link: landingpage?.[current?.slug] })
      } else {
        current.link = setRelativeLink({
          link: `/collections/${current?.slug}?ref=breadcrumb`,
        })
      }
      return current
    }

    return false
  }, [getCategory, product, type])

  return (
    <div className={cn(s.root, customClass?.crumbs, className, 'layer')}>
      <div className={cn(s.content, 'content line-clamp-1')}>
        {type === 'blog' && blog && (
          <>
            <div className={cn(s.block, customClass.block)}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}${
                  pageType === 'charger' ? '/' : '/anker-solix'
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {locale === 'eu-de' ? 'Startseite' : 'Home'}
              </a>
            </div>
            <span className={s.gang}>/</span>
            <div
              className={cn(s.block, s.last)}
              dangerouslySetInnerHTML={{
                __html: blog?.title,
              }}
            />
          </>
        )}

        {type === 'article' && blog_article && (
          <>
            <div className={cn(s.block, customClass.block)}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}${
                  pageType === 'charger' ? '/' : '/anker-solix'
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {locale === 'eu-de' ? 'Startseite' : 'Home'}
              </a>
            </div>
            <span className={cn(s.gang, customClass.gang)}>/</span>
            <div className={cn(s.block, customClass.block)}>
              <a
                href={`${
                  locale === 'us' ? '' : '/' + locale
                }/blogs?ref=breadcrumb`}
                className={s.crumb}
              >
                {blogNav?.title}
              </a>
            </div>
            <span className={cn(s.gang, customClass.gang)}>/</span>
            <div className={cn(s.block, customClass.block)}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}/blogs/${
                  blog_article?.handle
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {blog_article?.title}
              </a>
            </div>
            <span className={cn(s.gang, customClass.gang)}>/</span>
            <div
              className={cn(s.block, s.last, customClass.block)}
              dangerouslySetInnerHTML={{
                __html: blog_article?.article?.title,
              }}
            />
          </>
        )}

        {type === 'collection' && collection && (
          <>
            <div className={s.block}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}${
                  pageType === 'charger' ? '/' : '/anker-solix'
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {locale === 'eu-de' ? 'Startseite' : 'Home'}
              </a>
            </div>
            <span className={s.gang}>/</span>
            <div
              className={cn(s.block, s.last)}
              dangerouslySetInnerHTML={{
                __html: collection?.name,
              }}
            />
          </>
        )}

        {type === 'product' && product && (
          <>
            <div className={s.block}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}${
                  pageType === 'charger' ? '/' : '/anker-solix'
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {locale === 'eu-de' ? 'Startseite' : 'Home'}
              </a>
            </div>
            <span className={s.gang}>/</span>
            <div className={s.block}>
              <a href={followCollection?.link} className={s.crumb}>
                {followCollection?.name}
              </a>
            </div>
            <span className={s.gang}>/</span>
            <div
              className={cn(s.block, s.last)}
              dangerouslySetInnerHTML={{
                __html: product?.name,
              }}
            />
          </>
        )}

        {type === 'page' && page && (
          <>
            <div className={s.block}>
              <a
                href={`${locale === 'us' ? '' : '/' + locale}${
                  pageType === 'charger' ? '/' : '/anker-solix'
                }?ref=breadcrumb`}
                className={s.crumb}
              >
                {locale === 'eu-de' ? 'Startseite' : 'Home'}
              </a>
            </div>
            <span className={s.gang}>/</span>
            <div
              className={cn(s.block, s.last)}
              dangerouslySetInnerHTML={{
                __html: page?.seo?.title || page?.name || page?.handle,
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Crumbs
