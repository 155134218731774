import { getRecaptchaHeaders } from '@lib/utils/tools'
import isObject from 'lodash/isObject'
import handleFetchResponse from './utils/fetchResponse'

const getUrl = (url, body, repeatKey) => {
  const params = new URLSearchParams(joinParams(body, repeatKey)).toString()
  if (url.includes('?')) {
    return url + '&' + params
  }
  return url + '?' + params
}

const joinParams = (body, repeatKey) => {
  return isObject(body)
    ? Object.keys(body)
        .map((key) => {
          if (repeatKey && repeatKey === key) {
            // netlify 目前不兼容 next api 路由中，GET 请求中使用数据参数。后端接口做此兼容-数组拼接,转字符串
            return `${key}=${body[repeatKey]?.join(',')}`
          }
          return `${key}=${body[key]}`
        })
        .join('&')
    : ''
}

const fetcher = async ({
  action,
  needRecaptcha = false,
  locale,
  url,
  method = 'POST',
  headers = {},
  repeatKey,
  body,
  type,
  timeout = 30000,
}) => {
  // 添加谷歌验证
  let recaptchaHeaders = {}
  if (needRecaptcha) {
    recaptchaHeaders = await getRecaptchaHeaders(action, {
      recaptchaHeaderKey: 'recaptcha',
    })
  }

  let bodyData = ''
  if (type === 'uploadFile') {
    bodyData = body
  } else {
    bodyData = body ? JSON.stringify(body) : ''
  }

  let controller
  if (timeout) {
    // 超时报错
    controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
  }

  const data = await fetch(
    method === 'GET' ? getUrl(url, body, repeatKey) : url,
    {
      method,
      headers: {
        'current-language': locale,
        ...headers,
        ...recaptchaHeaders,
      },
      ...(timeout && { signal: controller.signal }),
      ...(method !== 'GET' && { body: bodyData }),
    }
  )
  return handleFetchResponse(data)
}

export default fetcher
