import { useRouter } from 'next/router'
import useSWR from 'swr'
import { useMemo, useState } from 'react'
import { getAllCollections } from './get-all-collection'

export const useAllCollections = () => {
  const { locale } = useRouter()

  const response = useSWR({ locale, options: {} }, getAllCollections)

  return useMemo(
    () =>
      Object.defineProperties(response, {
        isEmpty: {
          value: !response.data,
          enumerable: true,
        },
      }),
    [response]
  )
}
