import { CollectionConnection } from 'lib/shopify/gql'
import { getAllCollectionsQuery } from 'lib/shopify/queries/collection/get-all-collections'
import { Category } from 'lib/shopify/types/collection'
import { ShopifyFetcher } from 'lib/shopify/types/fetcher'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { normalizeCategory } from 'lib/utils/normalize'

export const getAllCollections = async ({
  locale = 'us',
  query = getAllCollectionsQuery,
}) => {
  const { res } = await fetchGraphqlApi<{ collections: CollectionConnection }>({
    query,
    locale,
  })

  if (res?.collections?.pageInfo?.hasNextPage) {
    const endCursor = res?.collections?.pageInfo?.endCursor || ''

    const { res: data_after } = await fetchGraphqlApi<{
      collections: CollectionConnection
      variables: {
        after?: string
      }
    }>({
      query,
      locale,
      variables: {
        after: endCursor,
      },
    })
    return res.collections.edges
      .concat(data_after.collections.edges)
      .map(({ node }: any) =>
        normalizeCategory({
          ...node,
        })
      )
  }

  return res.collections.edges.map(({ node }: any) =>
    normalizeCategory({
      ...node,
    })
  )
}
