import { collectionFragment } from 'lib/shopify/fragments/collection'
import { pageInfoFragment } from 'lib/shopify/fragments/page-info'

export const getAllCollectionsQuery = /* GraphQL */ `
  query getAllCollections($first: Int = 250, $after: String) {
    collections(first: $first, after: $after, sortKey: UPDATED_AT) {
      edges {
        node {
          ...collection
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${collectionFragment}
  ${pageInfoFragment}
`
