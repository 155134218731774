import { GTMtagsToCollection, tagsToCollection } from '@lib/utils/thirdparty'
import classNames from 'classnames'
import Decimal from 'decimal.js'
import { twMerge } from 'tailwind-merge'

export function getComponentData(
  metafields,
  dataKey,
  variant = null,
  components = null
) {
  // console.log("metafields", metafields)
  // 获取 component 的文案

  let data = {}
  if (components) {
    data = components[dataKey] || {}
  } else {
    data = metafields?.components ? metafields.components[dataKey] : {}
  }

  if (variant) {
    // 如果有且 variant 有对应文案，variant 覆盖 component
    const { variantsMetafields = {} } =
      components || metafields?.components || {}

    const variantMetafields = Array.isArray(variantsMetafields)
      ? variantsMetafields?.find((f) => f.id === variant?.id)
      : {}
    if (
      variantMetafields?.metafields &&
      variantMetafields.metafields[dataKey]
    ) {
      data = variantMetafields.metafields[dataKey]
    }
  }

  return {
    key: dataKey.split('_')[0],
    data,
  }
}

export function isObjEmpty(obj) {
  if (!obj) return true
  else {
    return Object.keys(obj).length === 0
  }
}

var antiString = function (obj) {
  return Function('"use strict";return (' + obj + ')')()
}
export const cssVal = function (str) {
  /**
   * Calculate an expression with css units
   * cssVal("30px - 20px"); // => 10px
   * cssVal("30% / 30%"); // => 1
   */
  const unitsRegex = new RegExp(
    'px|cm|vh|vw|em|rem|%|mm|in|pt|pc|ex|ch|vmin|vmax',
    'gm'
  )
  const calc = str.replace(unitsRegex, '')
  let unit = str.match(unitsRegex) && str.match(unitsRegex)[0]
  // Update from comment
  if (unit === '%') {
    unit = ''
  }
  const value = antiString(calc).toString() + unit
  return value
}

export function isStr(str) {
  return typeof str === 'string' || str instanceof String
}

export const noBlankStr = (str) => str.replace(/\s+/g, ' ').replace(/\n/g, ' ')

export const getOverrideData = (objOrder, key) => {
  // 覆盖逻辑，仅支持 object 类型数值覆盖
  // key 可以是 array 用于不同数据不同 key
  // 也可以是单个字符串，用于不同数据相同 key 之间的覆盖
  if (Array.isArray(key)) {
    return {
      ...(objOrder[objOrder.length - 1][key] || {}),
      ...(objOrder.find((obj, i) => obj[key[i]])?.[key] || {}),
    }
  } else {
    return {
      ...(objOrder[objOrder.length - 1][key] || {}),
      ...(objOrder.find((obj) => obj[key])?.[key] || {}),
    }
  }
}

// 转首字母大写, 展示可用 css
export const capitalize = (string) => {
  const [first, ...rest] = string
  return first.toUpperCase() + rest.join('').toLowerCase()
}

// 转 array of string 成单字符串，数组间隔换成换行
export const parseMessage = (message) => {
  if (Array.isArray(message)) {
    return message.reduce(
      (prev, cur) => prev + '\n' + cur.replace('_', ' '),
      ''
    )
  }
  return message
}

/**
 * 根据当前路由获取 gtm 的 group
 * 活动页：Activity Page_' + 页面 handle
 * 商详：Product Detail Page_ + 产品 handel
 * 博客：blog
 */
export const getGtmGroup = ({ query, asPath, ref }) => {
  if (asPath.includes('/blogs/')) {
    return 'blog'
  } else if (asPath.includes('/products/')) {
    return 'Product Detail Page_' + query?.slug
  } else if (asPath == '/') {
    return 'Home Page'
  } else {
    return (
      'Activity Page_' + ref || query?.pages?.[0]?.replace('storefront-', '')
    ) // 部分重定向页面
  }
}
// 判断该商品是否为 boa
// 匹配下划线或者横杠+ boa + 数字（可无，例如 -boa _boa1 -boa2 等
export const isBoa = (handle) => /[_|-]boa\d?$/.test(handle)

// 负数 =》 0
export const negativeToZero = (num) => Math.max(0, num)

// 获取 dom 在视区的百分比
export const getPercentOnView = (element, fixedTop = 65, fixedLeft = 24) => {
  if (!element) return 0
  // 盒子高度
  const rect = element.getBoundingClientRect()
  // viewport 高度
  const viewportHeight = window.innerHeight - fixedTop
  // 盒子顶部到 viewport 顶部的距离
  const top = rect.top - fixedTop
  // 盒子底部到 viewport 底部的距离
  const bottom = viewportHeight - top - rect.height
  // 盒子在 viewport 内的高度(最终计算时负数都应该变成 0)
  const heightInViewport = negativeToZero(
    viewportHeight - negativeToZero(top) - negativeToZero(bottom)
  )
  // 右侧到 viewport 的距离
  const right = window.innerWidth - rect.right
  const widthInViewport = negativeToZero(
    window.innerWidth -
      negativeToZero(rect.left - fixedLeft) -
      negativeToZero(right)
  )
  return (heightInViewport * widthInViewport) / (rect.height * rect.width)
}

export const $ = (el) => document.querySelector(el)

export const sleep = async (sec) =>
  new Promise((res) => setTimeout(res, sec * 1000))

// 下划线 => 驼峰
export const snakeToCamel = (str) =>
  !/[_-]/.test(str)
    ? str
    : str
        .toLowerCase()
        .replace(/([-_][a-z])/g, (group) =>
          group.toUpperCase().replace('-', '').replace('_', '')
        )

//把对象所有 key 由下划线变驼峰
export const keysSnakeToCamel = (obj) => {
  return Object.keys(obj).reduce(
    (prev, key) => ({
      ...prev,
      [snakeToCamel(key)]: obj[key],
    }),
    {}
  )
}

// 删除 value 为空的 pair
export const rmEmptyKeys = (obj) => {
  if (obj.constructor !== Object) {
    return obj
  } else {
    return Object.keys(obj)
      .filter((key) => obj[key])
      .reduce((prev, key) => ({ ...prev, [key]: obj[key] }), {})
  }
}

//merge object
export const deepMerger = (obj1, obj2) => {
  if (obj1 === undefined) return obj2
  for (let key in obj2) {
    obj1[key] = obj1?.[key] || ''
    if (obj1[key].toString == '[object Object]') {
      obj1[key] = deepMerger(obj1[key], obj2[key])
    } else {
      obj1[key] = obj2?.[key] || ''
    }
  }
  return obj1
}

// 通过产品 tags 得到对应的 collection, 需要的 collections 结构是 [{tags:['tag1', 'tag2'], ...}]
export const getCollectionByTags = (collectionsTags, tags) =>
  tags
    .split(',')
    ?.find((tag) => collectionsTags?.find((t) => t.trim() === tag.trim()))

export const cn = (...props) => twMerge(classNames(props))

// 验证当前路由匹配
export const checkPathName = ({ includes, excludes, pathname }) =>
  includes?.find(
    (path) =>
      new RegExp(path).test(pathname) &&
      !excludes?.find((path) => new RegExp(path).test(pathname))
  )

// 去除前后空格并转小写后比较
export const fuzzyEqual = (str1, str2) =>
  str1?.trim()?.toLowerCase() === str2?.trim()?.toLowerCase()

export const getMemberDiscount = ({ product, variant, memberDiscountData }) => {
  const value = memberDiscountData?.products?.find(
    ([p]) => p?.handle === product?.handle && p?.sku === variant?.sku
  )?.[0]?.value
  if (!value) return 0
  if (memberDiscountData?.discountType === 'percentage') {
    return new Decimal(variant?.price || 0)
      .times(value || 0)
      .toFixed(2, Decimal.ROUND_UP)
  } else if (memberDiscountData?.discountType == 'fix_amount') {
    return new Decimal(variant?.price || 0).minus(value || 0)
  } else {
    return new Decimal(value || 0)?.toNumber()
  }
}

export const hasMemberDiscount = (props) => getMemberDiscount(props) > 0

export const isGiftCard = (product) => {
  return product?.tags?.includes('giftCard')
}

export const isSinUpProduct = (product) => {
  return product?.tags?.includes('signup')
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const appendQueryParams = (url, ref) => {
  if (url?.includes('ref=')) return url
  const delimiter = url?.includes('?') ? '&ref=' : '?ref='
  return url + delimiter + ref
}
