import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo, NextSeo } from 'next-seo'
import config from '@config/seo.json'
import {
  ANKER_COUNTRY_SUFFIX,
  EUFY_COUNTRY_SUFFIX,
  ANKERMAKE_COUNTRY_SUFFIX,
  BRAND,
} from '@shopify/const'

const Head = ({ pageProps }) => {
  const { locale } = useRouter()

  const { metafields, seo, type } = pageProps
  const seoSetting = metafields?.seoSetting || {}
  const metaSetting = metafields?.metaSetting || {}
  const SEO = { ...config, ...seo, ...seoSetting }
  let COUNTRY_SUFFIX
  BRAND === 'anker' && (COUNTRY_SUFFIX = ANKER_COUNTRY_SUFFIX)
  BRAND === 'eufy' && (COUNTRY_SUFFIX = EUFY_COUNTRY_SUFFIX)
  BRAND === 'ankermake' && (COUNTRY_SUFFIX = ANKERMAKE_COUNTRY_SUFFIX)
  SEO.description =
    type === 'product'
      ? `${SEO.title} - ${SEO.description || ''}`?.substring(0, 155)
      : SEO?.description?.substring(0, 155)
  SEO.title = `${SEO.title} - ${COUNTRY_SUFFIX?.[locale]}`
  return (
    <>
      {SEO.noindex !== undefined ? (
        <NextSeo {...SEO} />
      ) : (
        <DefaultSeo {...SEO} />
      )}
      <NextHead>
        <title>{SEO?.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link rel="dns-prefetch" href="https://cdn.judge.me/" />
        <link rel="preconnect dns-prefetch" href="https://cdn.shopify.com" />
        <link rel="preconnect dns-prefetch" href="https://cdn.shopifycdn.net" />
        <link rel="apple-touch-icon" href="/favicon.png"></link>
        {/* <link
          rel="stylesheet"
          type="text/css"
          media="nope!"
          href="https://cdn.judge.me/shopify_v2.css"
          onLoad="this.media='all',function(){var d=document,e=d.createEvent('Event');e.initEvent('jdgm.doneLoadingCss',!0,!0),d.dispatchEvent(e)}();"
        /> */}
        {}
        {metaSetting?.extraMeta?.map((item, i) => {
          return <meta key={i} name={item?.name} content={item?.content} />
        })}
      </NextHead>
      {SEO?.h1 && (
        <h1
          className="m-0 h-0 overflow-hidden p-0 opacity-0 outline-0"
          dangerouslySetInnerHTML={{ __html: SEO?.h1 }}
        />
      )}
    </>
  )
}

export default Head
