import metafieldsItems from '../fragments/metafields'

export const collectionFragment = /* GraphQL */ `
  fragment collection on Collection {
    id
    title
    handle
    description
    seo {
      title
      description
    }
    image {
      id
      width
      height
      altText
      url
    }
    products(first: 8) { 
      edges {
        node {
          id
          vendor
          title
          handle
          tags
          productType
          availableForSale
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          MF_productTags: metafield(namespace: "infos", key: "productTags") {
            ${metafieldsItems}
          }
          MF_productNote: metafield(namespace: "infos", key: "productNote") {
            ${metafieldsItems}
          }
          variants(first: 10) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                barcode
                availableForSale
                requiresShipping
                quantityAvailable
                currentlyNotInStock
                selectedOptions {
                  name
                  value
                }
                image {
                  altText
                  url
                  width
                  height
                }
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                MF_infos: metafield(namespace: "variant", key: "infos") {
                  ${metafieldsItems}
                }
              }
            }
          }
          images(first: 1) {
            edges {
              node {
                url
                altText
                width
                height
              }
            }
          }
        }
      }
    }
    MF_series_introduction: metafield(
      namespace: "global"
      key: "series_introduction"
    ) {
        ${metafieldsItems}
    }
  }
`
